
import { setToken } from '@/utils/auth'
import { Toast } from 'vant'

export function isMobile(mobile) {
  return /^1[3456789]\d{9}$/.test(mobile)
}

export function loginCb(token) {
  setToken(token)
  Toast('登录成功')
  goBackPath()
}
export function goBackPath(){
  let backpath = sessionStorage.getItem("backpath");
  if (!backpath) {
    // 默认跳转到对应环境的m站
    backpath =
      location.protocol + "//" + location.hostname.replace(/login|pass/, "m");
  }
  window.location.href = backpath;
}

export function checkBtn(form) {
  let disabled = false
  for(let k in form) {
    let val = form[k]
    if(k === 'mobile' && !isMobile(val)) {
      disabled = true
    }
    if(k === 'code' && val.length < 4) {
      disabled = true
    }
    if(k === 'password' && val.length < 6) {
      disabled = true
    }
  }
  return disabled
}

export function getQuery(name) {
  var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)")
  var r = window.location.search.substr(1).match(reg)
  if (r != null) return unescape(r[2])
  return null
}