import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

// 解决路由重复报错的问题
const originalPush = Router.prototype.push
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

export default new Router({
	mode: 'history',
	routes: [
    {
      path: '/',
      name: 'code',
      component: () => import('@/views/index'),
      meta: {
        title: '快捷登录'
      }
    },
    {
      path: '/m_station/login/',
      name: 'mstation',
      component: () => import('@/views/index'),
      meta: {
        title: '快捷登录'
      }
    },
    {
      path: '/pwd',
      name: 'password',
      component: () => import('@/views/pwd'),
      meta: {
        title: '密码登录'
      }
    },
    {
      path: '/reg',
      name: 'reg',
      component: () => import('@/views/reg'),
      meta: {
        title: '注册账号'
      }
    },
    {
      path: '/forgot',
      name: 'forgot',
      component: () => import('@/views/forgot'),
      meta: {
        title: '忘记密码'
      }
    },
    {
      path: '/bind',
      name: 'bind',
      component: () => import('@/views/bind'),
      meta: {
        title: '绑定手机号'
      }
    },
    {
      path: '/pc/oauth/weixin',
      name: 'pc',
      component: () => import('@/views/pc'),
      meta: {
        title: 'pc微信扫码登录'
      }
    },
    {
      path: '/hj/login',
      name: 'hujiang',
      component: () => import('@/views/hujiang'),
      meta: {
        title: '沪江登录'
      }
    },
    {
      path: '/404',
      component: () => import('@/views/404'),
      hidden: true
    },
    {
      path: '/401',
      component: () => import('@/views/401'),
      hidden: true
    },
    {
      path: '*',
      hidden: true,
      redirect: { path: '/404' }
    },
  ]
})

