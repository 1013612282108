import Vue from 'vue'
import router from './router'
import App from './App.vue'

import '@/styles/base.less' // global css
import '@/styles/index.less'
import Vant from 'vant'
import 'vant/lib/index.css'
Vue.use(Vant)

import { isMobile, loginCb, checkBtn } from '@/utils/base'
Vue.prototype.$isMobile = isMobile
Vue.prototype.$loginCb = loginCb
Vue.prototype.$checkBtn = checkBtn

Vue.config.productionTip = false

router.beforeEach((to, from, next) => {
  // 路由发生变化修改页面title
  if (to.meta.title) {
    document.title = to.meta.title + ' - 研途教育'
  }
  if (to.query.backpath) {
    sessionStorage.setItem('backpath', to.query.backpath)
  }
  //存在tq直接存储；tq_id 存储整个链接
  // tq为存在tq_id的链接
  // tq_id为单独的id
  if (to.query.tq || to.query.tq_id) {
    sessionStorage.setItem(
      'tq',
      encodeURIComponent(to.query.tq || encodeURIComponent(location.href))
    )
  }
  let toKeys = Object.keys(to.query).length
  let fromKeys = Object.keys(from.query).length
  if (fromKeys && !toKeys) {
    next({
      path: to.path,
      query: { ...from.query },
    })
  } else {
    next()
  }
})

new Vue({
  router,
  render: (h) => h(App),
}).$mount('#app')
