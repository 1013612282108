import Cookies from 'js-cookie'

const TokenKey = 'Msite-Token'

let hostArr = location.hostname.split('.')
if (hostArr.length > 1) {
  hostArr = hostArr.slice(1)
} 
let domain = '.' 
let hostStr = hostArr.join('.')
if (hostStr !== 'localhost') {
  domain = domain + hostStr
} else {
  domain = 'localhost'
}

// console.log('domain', domain)
// const domainCookies = Cookies.withAttributes({ domain })

export function getToken() {
  return Cookies.get(TokenKey)
}

export function setToken(token) {
  return Cookies.set(TokenKey, token, {
    domain: domain
  })
}

// export function removeToken() {
//   return Cookies.remove(TokenKey)
// }

// export function setDomainCookie(key, v, options = {}) {
//   return Cookies.set(key, v, {
//     domain: domain,
//     ...options
//   })
// }

// export function removeDomainCookie(key) {
//   return Cookies.remove(key, {
//     domain
//   })
// }

export function getDomainCookie(key) {
  return Cookies.get(key, {
    domain: domain
  })
}

window.$$getDomainCookie = getDomainCookie
// window.$$domainCookies = domainCookies
